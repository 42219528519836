<template>
  <div class="resourceManage fx-col fx-1">
    <el-card>
      <div>
        <el-form
          class="form-item-no-mb"
          ref="searchForm"
          inline
          :model="searchForm"
          size="small"
        >
          <el-form-item label="文件名">
            <el-input
              v-model="searchForm.name"
              clearable
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <el-form-item label="文件类型">
            <el-select v-model="searchForm.type" clearable placeholder="请选择">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button>重置</el-button>
          </el-form-item>
          <el-form-item>
            <el-button type="primary">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-card>
    <div class="fx-row mt16 bac-fff fx-1">
      <!-- 资源分组 -->
      <div class="left-box">
        <div class="left-box-title">资源分组</div>
        <div class="left-box-tree">
          <el-tree :data="data" :props="defaultProps"></el-tree>
        </div>
      </div>
      <!-- 列表 -->
      <div class="w200 fx-1">
        <el-table
          class="index-list-table"
          :data="tableData"
          height="calc(100vh - 350px)"
        >
          <el-table-column prop="name" label="文件名称"></el-table-column>
          <el-table-column prop="name" label="所属分组"></el-table-column>
          <el-table-column prop="name" label="文件类型"></el-table-column>
          <el-table-column prop="name" label="文件大小"></el-table-column>
          <el-table-column prop="name" label="文件时长(min)"></el-table-column>
          <el-table-column label="操作" width="240" fixed="right">
            <el-button type="text" class="text-btn mr24">预览</el-button>
            <el-button type="text" class="text-btn mr24">下载</el-button>
          </el-table-column>
        </el-table>
        <el-pagination
          class="fx-row-end pt16"
          @size-change="_handleSizeChange"
          @current-change="_handleCurrentChange"
          :current-page="searchForm.page"
          :page-sizes="[10, 20, 30, 40, 50]"
          :page-size="searchForm.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="tableTotal"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data () {
    return {
      tableData: [{ name: '111' }, { name: '111' }],
      searchForm: {
        name: '',
        type: '',
        page: 1,
        pageSize: 10
      },
      data: [
        {
          label: '一级 1',
          children: [
            {
              label: '二级 1-1',
              children: [
                {
                  label: '三级 1-1-1'
                }
              ]
            }
          ]
        },
        {
          label: '一级 2',
          children: [
            {
              label: '二级 2-1',
              children: [
                {
                  label: '三级 2-1-1'
                }
              ]
            },
            {
              label: '二级 2-2',
              children: [
                {
                  label: '三级 2-2-1'
                }
              ]
            }
          ]
        },
        {
          label: '一级 3',
          children: [
            {
              label: '二级 3-1',
              children: [
                {
                  label: '三级 3-1-1'
                }
              ]
            },
            {
              label: '二级 3-2',
              children: [
                {
                  label: '三级 3-2-1'
                }
              ]
            }
          ]
        }
      ],
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      tableTotal: 0,
      options: [
        {
          value: '选项1',
          label: '黄金糕'
        },
        {
          value: '选项2',
          label: '双皮奶'
        },
        {
          value: '选项3',
          label: '蚵仔煎'
        },
        {
          value: '选项4',
          label: '龙须面'
        },
        {
          value: '选项5',
          label: '北京烤鸭'
        }
      ]
    }
  },
  methods: {
    _handleSizeChange (val) {
      this.searchForm.pageSize = val
      // this._getListPage()
    },
    _handleCurrentChange (val) {
      this.searchForm.page = val
      // this._getListPage()
    }
  }
}
</script>
<style lang="scss" scoped>
.form-item-no-mb {
  /deep/.el-form-item {
    margin-bottom: 0;
  }
}
/deep/.index-list-table {
  thead {
    background-color: #ffffff !important;
  }
  tr {
    height: 51px;
    height: 54px;
    color: rgba(0, 0, 0, 0.9);
    font-size: 14px;
  }
  th {
    background: #f6f7f9 !important;
  }
}
.add-group-btn {
  width: 116px;
  border: 1px solid #0089ff;
  font-size: 14px;
  color: #0089ff;
}
.delete-group-btn {
  width: 60px;
  border: 1px solid #f04134;
  font-size: 14px;
  color: #f04134;
}
/deep/.el-form-item {
  margin-bottom: 16px !important;
  margin-right: 32px !important;
}
/deep/.el-form-item__label {
  color: #000000;
}
/deep/ .el-button {
  font-size: 14px;
  height: 32px;
}
/deep/.el-button--text {
  margin: 0 10px 0px 0!important;
}
.left-box {
  margin-right: 8px;
  width: 260px;
  border-right: 1px solid #f3f3f3;
  .left-box-title {
    padding: 16px 16px;
    font-size: 16px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.9);
    border-bottom: 1px solid #f3f3f3;
  }
}
/deep/.el-tree-node__label {
  font-weight: 500;
  color: #303133;
}
// /deep/.el-icon-caret-right {
//   color: #303133;
// }
</style>
